"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.embedDashboard = embedDashboard;
exports.embedQSearchBar = embedQSearchBar;
exports.embedSession = embedSession;
exports.embedVisual = embedVisual;
var _EmbeddableObject = _interopRequireDefault(require("./EmbeddableObject"));
var _EmbeddableDashboard = _interopRequireDefault(require("./EmbeddableDashboard"));
var _EmbeddableVisual = _interopRequireDefault(require("./EmbeddableVisual"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/**
 * Embed a visual.
 * @function
 * @name embedVisual
 * @param {VisualEmbeddingOptions} options - options set by customers to embed the visual.
 */
function embedVisual(options) {
  var visual = new _EmbeddableVisual["default"](options);
  var container = visual.getContainer();
  setTimeout(attachToDom.bind(null, visual.iframe, container), 0);
  return visual;
}

/**
 * Embed a dashboard.
 * @function
 * @name embedDashboard
 * @param {EmbeddingOptions} options - options set by customers to embed the dashboard.
 */
function embedDashboard(options) {
  var dashboard = new _EmbeddableDashboard["default"](options);
  return embedObject(dashboard);
}

/**
 * Embed a session.
 * @function
 * @name embedSession
 * @param {EmbeddingOptions} options - options set by customers to embed the session.
 */
function embedSession(options) {
  var embeddedSession = new _EmbeddableObject["default"](options);
  return embedObject(embeddedSession);
}

/**
 * Embed Q search bar.
 * @function
 * @name embedQSearchBar
 * @param {EmbeddingOptions} options - options set by customers to embed the Q search bar.
 */
function embedQSearchBar(options) {
  var embeddedQSearchBar = new _EmbeddableObject["default"](_objectSpread(_objectSpread({}, options || {}), {}, {
    isQEmbedded: true
  }));
  return embedObject(embeddedQSearchBar);
}
function embedObject(embeddableObject) {
  var container = embeddableObject.getContainer();
  setTimeout(attachToDom.bind(null, embeddableObject.iframe, container), 0);
  return embeddableObject;
}

/**
 * Create a iframe and attach it to parent element.
 * @function
 * @name attachToDom
 * @param {HTMLIFrameElement} iframe
 * @param {string} url - url of the dashboard to embed with parameter values appended.
 * @param {HTMLElement} container - parent html element.
 */
function attachToDom(iframe, container) {
  if (!iframe) {
    throw new Error('iFrame is required');
  }
  if (!container) {
    throw new Error('container of iFrame is required');
  }
  container.appendChild(iframe);
}